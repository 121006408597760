<template>
  <div class="w-full h-screen flex justify-center items-center">
      <!-- Background Image -->
      <div class="absolute inset-0 z-0">
        <img src="../../assets/images/bg.jpg" alt=""
            class="w-full h-full object-cover filter blur-lg brightness-100">
    </div>
    <div class="w-full m-2 relative  md:w-1/4 md:m-0 shadow-2xl p-4  rounded-xl ">
      <div class="flex justify-center">
        <img src="../../assets/icons/ic-logo.svg" class="h-32 w-40">
      </div>
      <div class="form mt-8">
        <div class="form-item">
          <h3 class="text-xl text-black mb-2">
            {{$t('fullname')}}
          </h3>
          <input type="text" class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="fullname">
        </div>
        <div class="form-item mt-4">
          <h3 class="text-xl text-black mb-2">
            {{$t('email')}}
          </h3>
          <input type="email" class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="email">
        </div>


        <div class="form-item mt-4">
          
          <h3 class="text-xl text-black mb-2">
            {{$t('phone')}}
          </h3>
          <div class="flex">
            <div style="margin: auto" class="text-xl border rounded-xl h-16  p-2 text-black">
          <vue-country-code
            @onSelect="onSelect"
            :preferredCountries="['vn', 'us', 'gb']"
          ></vue-country-code>
        </div>
          <input  placeholder="ex:77XXXXXXXXX" class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="phone">
          </div>
         
        </div>
        <div class="form-item mt-4">
          <h3 class="text-xl text-black mb-2">
            {{$t('password')}}
          </h3>
          <input type="password" class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="password">
        </div>
        <div @click="goSignIn" class="mt-4 h-16 w-full flex items-center cursor-pointer rounded-xl next-button justify-center text-center text-xl text-white">
          <span v-if="!showLoader" >{{ $t('next') }}</span>
          <pulse-loader v-if="showLoader"  :color="color"></pulse-loader>
        </div>
        <div class="create-account flex justify-center mt-3 text-black cursor-pointer mb-3" @click="goNext">
          {{ $t('alreadyLogin')  }}
        </div>
        <div  class="create-account flex justify-center mt-3 text-black cursor-pointer mb-3" @click="resetPassword">
          {{ $t('Réitiliser mot de passe')  }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueCountryCode from "../../components/VueCountryCode.vue";
import firebase from 'firebase'
import { db } from '../../main'
import databaseRef from '../../utils/database.ref'

export default {
  name: 'SignUp',
  components: { PulseLoader, VueCountryCode},
  data () {
    return {
      color: '#ffffff',
      showLoader: false,
      fullname: '',
      email: '',
      phone: '',
      code: '',
      password: '',
      name: '',
      iso: '',
      selectedCountry: null
    }
  },
  methods: {
    onSelect({name, iso2, dialCode}) {
      //this.selectedCountry = data;
      this.code = dialCode;
      this.name = name;
      this.iso = iso2;
      /*console.log(this.code);
      console.log(iso2 + "  "+dialCode);
      console.log('Pays sélectionné:', name);*/
    },
    goSignIn () {
      if (this.email === '' || this.password === '' || this.fullname === '' || this.phone === ''  || this.code === '' || this.name === '' || this.name === '' ) {
        alert('All inputs are required')
      } else {
        this.showLoader = true
        const vm = this
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then((result) => {
              // Envoi de l'e-mail de vérification (automatique par Firebase)
        result.user.sendEmailVerification()
          .then(() => {
            // Attendre que la vérification soit terminée
            result.user.reload().then(() => {
              // Vérifier si l'e-mail est vérifié
              if (result.user.emailVerified) {
                // Redirection vers la page d'accueil
                vm.$router.push('/')
              } else {
                alert("Please verify your email.")
              }
            })
          })
          .catch((error) => {
            console.error(error)
          })
            db.collection(databaseRef.users)
              .doc(result.user.uid)
              .set({
                email: vm.email,
                password: vm.password,
                fullname: vm.fullname,
                code: vm.code,
                phone: vm.phone,
                name: vm.name,
                iso: vm.iso,
                balance: 0
              })
              
              .then(() => {
                vm.$router.push('/')
              })
          })
          .catch((err) => {
            alert(err)
          })
      }
    },
    goNext () {
      this.$router.push('/login')
    },
    // onSelect({name, iso2, dialCode}) {
    //    console.log(name, iso2, dialCode);
    //  },
    resetPassword() {
      const email = prompt("Veuillez saisir votre adresse e-mail :")
      if (email) {
        firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            alert("Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.")
          })
          .catch((error) => {
            console.error(error)
            alert("Une erreur s'est produite lors de l'envoi de l'e-mail de réinitialisation de mot de passe.")
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
.next-button {
  background-color: $color-primary;
}
</style>

